li{
    list-style-type: none;
    color: white;
    cursor: pointer;
}
li:hover{
    color: rgb(228, 169, 255);
}
ul{
    padding: 0px;
    text-align: center;
}
a{
   // color: white;
    text-decoration: none;
}