$backgroundcolor:rgb(139, 139, 139);
.reservation-input{
    color: rgb(0, 0, 0);
    border-color: rgb(53, 53, 53);
    margin-top: 0px;
    min-width: 200px;
    max-width: 400px;
    max-height: 35px;
    width: 90%;
    border-radius: 2px;
}

.reservation-textarea{
    max-height: 70px;
}

option{
    background-color: $backgroundcolor;
}

option:hover{
    color: white;
    background-color: black;
}

.reservation-input::placeholder{
    color: rgb(94, 94, 94);
}
.reservation-input::-ms-input-placeholder{
    color: rgb(94, 94, 94);
}

.resize-block{    
    text-align: center;
    resize: horizontal;
    container-type: inline-size;
}
.header-text{
    background-color: $backgroundcolor;    
    color: black;
    padding: 10px;
    font-size: 6cqw;
    margin-bottom: 15px;    
    font-weight: 500;
}

.body-block{    
    font-weight: 400;
    background-color: $backgroundcolor;    
    color: black;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    font-size: 6cqw;
    text-align: left;    
}

.black-text{
    display: block;  
}
.live-text{
    font-size: 4cqw;
}
.price-text{
    font-size: 4cqw;
}
.reservation-inputs{
    text-align: center;
}
.small-note-text{
    font-size: 2.4cqw;
    margin-bottom: 10px;
}
.checkbox{
    font-size: 3cqw;
    text-align: center;
    display: flex;
    justify-content: center;
}
.checkbox>input{
    height: 4cqw;
    width: 4cqw;
    padding-top: 0px;
    margin-top: 1px;
}

.go-back-buttons{
    display: flex;
    justify-content: space-evenly;
}

.go-back-buttons-text{
    font-size: 4cqw;
}

.confirmation-text{
    font-size: 3.5cqw;
    text-align: left;
    padding-bottom: 10px;
}

.confirm-border{
    border: 2px solid black;
    margin: 0 -10px -10px -10px;
    padding: 10px;
}

.confirmation-header{
    font-size: 5cqw;
    font-weight: 530;
    text-align: center;
    padding-bottom: 4%;
}
.confirmation-header>div{
    font-size: 4cqw;
    font-weight: 350;
}

.japanese-confirmation-text{
    padding: 10px;
    padding-left: 5px;
    padding-bottom: 5%;
}

.reservation-inputs>label{
    display: flex;
    padding-left: 2%;
    font-size: 3.5cqw;
    font-weight: 400;
    padding-top: 5px;
    flex-wrap: wrap;
}

.confirmation-page-logo{
    width: 12%;
    position: relative;
    display: flex;
    left: 50%;
    transform: translate(-50%, 0);
    margin-top: 5%;
    margin-bottom: 5%;
}

.red-text{
    color: rgb(160, 14, 14);
}

