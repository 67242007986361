.merch-content{
    margin-top: 120px;
    max-width: 500px;
    width: 90%;
    min-width: 300px;
    text-align: center;
}
.merch-images-block{
    margin-bottom: 30px;
}

.slider{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 250px;
    overflow: hidden;
}

@keyframes slideleftanimation{
    0%, 10% {right:-500px; opacity: 0;}
    90%, 100% {right:0;opacity: 1;}
}

@keyframes sliderightanimation{
    0%, 10% {left:-500px; opacity: 0;}
    90%, 100% {left:0;opacity: 1;}
}
@keyframes cartanimation{
    0%, 10% {opacity: 0.5;}
    90%, 100% {opacity: 1;}
}

@keyframes carttextanimation{
    0%, 10% {opacity: 1;}
    90%, 100% {opacity: 0;}
}

.main-slider-image{
    max-width: 500px;
    width: 65%;
    min-width: 200px;
    position: relative;
}

.slider-left-animate{
    animation-duration: 0.5s;
    animation-name: slideleftanimation;
}

.slider-right-animate{
    animation-duration: 0.5s;
    animation-name: sliderightanimation;
}

.arrow-slider{
    width: 20px;
    height: 20px;
    background-color: rgb(209 209 209 / 42%);
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
}

.left-arrow{
    margin-right: 20px;
}
.right-arrow{
    margin-left: 20px;
}

.merch-info-block{
    position: relative;
    padding: 10px;
    text-align: center;
    width:80%;
    left: 50%;
    transform: translate(-50%,0);
}

.merch-info-block #title{
    padding-top: 24px;
    padding-bottom: 20px;
    font-size: 16px;
    font-weight: 600;
}

.merch-info-block > #title > div{
    display: inline-block;
    vertical-align: middle;
}

.merch-info-block #description{
    display: inline-block;
    vertical-align: top;
    width: 50%;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
}

.merch-info-block > #description > #left-description{
    padding-left: 15px;
}


.merch-info-block > #description > p,.merch-info-block > #description > #left-description >p {
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
}

.button-block .button{
    margin-top: 20px;
    margin-bottom: 20px;
    width: 80%;
    max-width: 300px;
    position: relative;
    left: 50%;
    transform: translate(-50%,0);
}

.merch-block{
    margin-bottom: 80px;    
    padding-bottom: 20px;
    padding-top: 20px;
  //  background-color: rgba(255, 255, 255, 0.116);
}



.cart{
    animation-duration: 0.2s;
    animation-iteration-count: 2;
    animation-name: cartanimation;
    width:50px;
    cursor: pointer;
    position: relative;
}

.merch-page-cart{
    position: fixed;
    z-index: 9;
    margin-top: 110px;
    width: 1000px;
    display: flex;
    justify-content: flex-end;
    margin-right: 180px;
}

.cart>img{
    width:40px;
}

.cart>div{
    position: absolute;
    top: -20px;
    right: 23px;
    color: white;
    font-size: 18px;
    font-weight: 700;
    padding: 3px 6px 3px 6px;
    border-radius: 50%;
}

.cart>label{
    position: absolute;
    width: 240px;
    left:-200px;
    top:0px;
    color: white;
    font-size: 18px;
    font-weight: 600;
    animation: carttextanimation;
    animation-duration: 5s;
    animation-iteration-count: 1;
    opacity: 0;
}

.merch-info-block > #waribiki{
    position: absolute;
    display: block;
    right: -30px;
    top: -80px;
    width: 130px;
}


@media only screen and (max-width: 1000px){
    .merch-page-cart{
        width: auto;
        margin-top: 130px;
        margin-right: 0px;
        right: 85px;
    }
}

@media only screen and (max-width: 650px){
    .merch-page-cart{
        width: auto;
        margin-top: 130px;
        margin-right: 0px;
        right: 25px;
    }
}

@media only screen and (max-device-width: 500px){
    .merch-content{
        margin-top: 40px;
    }

    .merch-page-cart{
        margin-top: 20px;
        right: 20px;
    }
}