.contacts {
    max-width: 500px;
    width: 90%;
    margin-top: 140px;
}

@media only screen and (max-device-width: 500px){
    .contacts{
        margin-top: 70px
    }
}