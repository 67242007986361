
.button{
    cursor: pointer;
    height: 50px;
    border: solid 1px black;
    text-align: center;
    font-size: 20px;
    color: black;
    margin-top: 10px;
    min-width: 120px;
    background-color: transparent;
}

.button>div{
    margin: 0;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.white-button{
    background-color: aliceblue;
    color: black;
}

.center-content{
    margin: 0;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.button:hover{
    background-color: black;
    color: white;
}

.day-number{
    font-size: 45cqh;
}
.day-word{
    font-size: 16cqh;
}

.month-font{
    font-size: 1.6em;
}

.modal{
    margin: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    display: flex;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0,0,0,.5);
    justify-content: center;
    z-index: 12;
}

.modal>img{
    display: flex;
    position: fixed;
    top:50%;
    transform: translate(0, -50%);
}

video{
    position: fixed;
    z-index: -1;
    top:50%;
    transform: translate(0, -50%);
}

.small-text{
    font-size: 11px;
}

.anchor{
    position: absolute;
    margin-top: -100px;
}

@media only screen and (max-device-width: 500px){
    .button{
        font-size: 13px;
    }

}