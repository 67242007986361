.character{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 200px;
}
.character>img{
    width:7%;
    margin: 10px;
    min-width: 140px;
    cursor: pointer;
}

.character-modal{
    width: 80%;
    height: auto;
    max-width: 500px;
    position: fixed;
    padding-bottom: 20px;
    left: 50%;
    top:50%;
    transform: translate(-50%,-50%);
    background-color: rgba(255, 255, 255, 0.616);
    z-index: 10;
}

.character-modal>div{
    left: 50%;
    transform: translate(-50%,0);
    position: relative;
}

.character-modal> div:first-child{
    display: block;
    width: 40%;
    max-width: 120px;
    margin-top: 25px;
}

.character-modal> div>img{
    width: 100%;
}

.character-modal #name,#position{
    display: block;
    width: fit-content;
    padding-top: 0px;
    font-size: 16px;
}
.character-modal #name{
    font-weight: 1000;
}
.character-modal #photos {
    padding-top: 20px;
    text-align: center;
    max-width: 350px;
    overflow-y: auto;
}

.character-modal #photos > img{
    width: 25%;
    padding: 5px;
    max-width: 90px;
}

.close-modal{
    width: 30px;
    height: 30px;
    position: fixed;
    top: -17px;
    right: -17px;
    background-color: rgba(0, 0, 0, 0.315);
    padding: 4px;
    border-radius: 50%;
}

.enlarge-image{
    width: 80%;
    max-width: 400px;
}

@media only screen and (max-device-width: 500px){
    .character{
        margin-top: 140px;
    }
    .character>img{
        min-width: 60px;
        width: 17%;
        margin: 4px;
    }
}
