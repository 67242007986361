$backgroundcolor:rgb(139, 139, 139);

.streaming-list > a > div > img {

}

.streaming-list > a{
    width: auto;
    display: flex;
    justify-content: center;
}

.last-release-video > iframe{
    width: 450px;
    height: 254px;
}
.last-release-video{
    display: block;
    width: 100%;
}

.music-content > div {
    margin-bottom: 50px;
}

.music-content{
    padding-top: 120px;
}
.music-content .gray-card{
    width: 100%;
    margin-top: 30px;
    position: relative;
    left: 50%;
    transform: translate(-50%, 0);
}

.music-content  .last-release-title{
    font-size: 24px;
    font-weight: 800px;
    background-color: $backgroundcolor;
    text-align: center;
    margin-bottom: 20px;
}

.music-content  .last-release-title>div{
    width: 100%;
    position: relative;
    left: 50%;
    transform: translate(-50%,0);
    border: 1px solid black;
    padding-top: 10px;
    padding-bottom: 10px;
}

.streaming-list{

}
.streaming-icons-list{
    width: 100%;
    display: block;
    text-align: center;
    margin-top: 10px;
}
.streaming-icons-list > a > div,.streaming-icons-list > a{
    display: inline-block;
}
.streaming-icons-list > a > div{
    padding-right: 25px;
    padding-left: 25px;
}
.streaming-icons-list > a > div > img{
    width: 30px;    
    max-height: 40px;
    display: inline-block;
    vertical-align: middle;
    position: relative;
}

.streaming-icons-list > a > div > #APPLE-MUSIC{
    margin-top: -8px;
}
.streaming-list > a > div {
    border-bottom: 1px solid black;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 80%;
}

.streaming-list > a > div > div {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    width: 300px;
    text-align: center;
}

@media only screen and (max-device-width: 500px){
    .last-release-video > iframe{
        width: 350px;
        height: 198px;
    }
    .music-content{
        padding-top: 40px;
    }
    .streaming-list > a > div > div{
        width: 200px;
    }
    
    .music-content > div {
        margin-bottom: 60px;
    }
    
}