.news-button-shadow{
    box-shadow: rgba(254, 118, 196, 0.58) 0px 15px 40px 0px, rgb(215 164 155) 10px -10px 40px 10px;
}

.live-button-shadow{
    box-shadow: rgb( 190, 131, 173 ) 10px 5px 50px 20px, rgba(254, 154, 201, 0.438) -5px -5px 30px 0px;
}

.music-button-shadow{
    box-shadow:rgb(192 132 254 / 74%) -5px 0px 30px 0px, rgb(148 238 248 / 70%) 20px 5px 50px 10px;
}

.merch-button-shadow{
    box-shadow:rgb(254 160 241 / 50%) 0px 0px 40px 20px;
}

.bio-button-shadow{
    box-shadow:rgb(144 196 254 / 74%) -10px 5px 20px 0px, rgb(148 238 248 / 70%) 10px 5px 40px 10px;
}

.contact-button-shadow{
    box-shadow:rgb(144 254 237 / 74%) -5px -5px 15px 0px, rgb(201 175 254 / 70%) 5px 5px 40px 20px;
}

@keyframes radioanimation{
    0%, 10% {opacity: 0.6; }
    90%, 100% {opacity: 1;}
}

.radio{
    width: 50px;
    height: 60px;
    position: fixed;
    display: flex;
    cursor: pointer;
    bottom: 100px;
    left: 25%;
    animation-duration: 2s;
    animation-name: radioanimation;
    animation-iteration-count: infinite;
    z-index: 50;
}

.radio>div{
    color: white;
    display: block;
    padding-left: 5px;
}
@media only screen and (max-width: 1100px)
{
    .radio{left:50px}
}
@media only screen and (max-device-width: 500px){
    .radio{
        width: 30px;
        height: 40px;
        bottom: 90px;
        left: 20px
    }
}