$mainbluecolor:#1b294c;

html {
    scroll-behavior: smooth;
  }
  
.input-title{
    margin-bottom: 5px;
}

input{
    width: 240px;
    background-color: transparent;
    height: 50px;
    border: solid 1px gray;
    border-radius: 5px;
    padding-left: 5px;
    font-size: 20px;
    color:#e7e7e7;
}

.card-title{
    font-size: larger;
    margin-top: -20px;
}

.input-block{
    margin-top:10px;
    display:block;
    width:250px;
}

select{
    width:250px;
    height:50px;
    font-size:20px;
    border-radius:5px;
    background-color: transparent;
    color:#ffffff;
}

textarea{
    resize: none;
    width:250px;
    height:200px;        
    background-color: transparent;    
    color:#e7e7e7;
}

.cardscontainer{
    display: inline-flex;
    flex-wrap: wrap;
}

.page{
    background-color: #818181;
}

// @font-face {
//     font-family: 'Corporate-Logo-Bold-ver2';
//     src:local('Corporate-Logo-Bold-ver2'), url(../fonts/Corporate-Logo-Bold-ver2.ttf) format('truetype'); 
// }
// @font-face {
//     font-family: 'Kozuka-Gothic-Pr6N-M';
//     src:local('Kozuka-Gothic-Pr6N-M'), url(../fonts/Kozuka-Gothic-Pr6N-M.ttf) format('truetype'); 
// }

.grayFont{
    font-size: 12px;
    color: gray;
}

.defaultFont{
    color: $mainbluecolor;
}

.admin-page{
    font-family: 'Segoe UI';
}

.green-font{
    color: #3fa054;
}

.text-center{
    text-align: center;
}

.padding-top-10{
    padding-top: 10px;
}

.center-element{
    width: 100%;
    display: flex;
    justify-content: center;
}

div.MuiPaper-root {
    background-color: #c2bfbf;
}

.livename-overflow-hide{
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.reservation-block{
    max-height: 300px;
    overflow-y: auto;
}

.reservations-table{
    border-collapse: collapse;
    width: 100%;
}

.reservations-table input{
    max-width: 100px;
}

.reservations-table > thead, 
.reservations-table >thead>  th,
.reservations-table >tbody> tr,
.reservations-table >tbody> tr > td
{ 
    border: 1px solid gray;
    padding: 5px;
}

.reservations-table > thead >  th > input {
    display: block;
    height: 20px;
    left: 50%;
    position: relative;
    transform: translate(-50%, 0);
    margin-top: 5px;
    margin-bottom: 5px;
    color: black;
}

@media only screen and (max-device-width: 500px){
    .reservations-table > thead >  th > input{
        max-width: 100px;
    }
}

.images-list>img{
    width:15px;
    margin-left: 10px;
    background-color: gray;
    padding:5px;
    border-radius: 50%;
    cursor: pointer;
}

.images-list>li{
    margin-bottom: 5px;
    max-width: 120px;
    overflow:hidden;
    text-overflow: ellipsis;
    display: inline-block;
    width: 80%;
}