// @keyframes coloranimation{
//     0%, 10%  {background-color: black; }
//     90%, 100% {background-color: rgb(255, 255, 255)}
// }
.loading-screen{
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.953);
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    transition: visibility 0.5s;
    // animation-name: coloranimation;
    // animation-duration: 0.5s;
    // animation-iteration-count: infinite;
    // animation-direction: alternate;
    // animation-delay: 0s;
}

@keyframes rotateanimation{
    0%, 10% {opacity: 1; width: 10px; height: 10px;}
    90%, 100% {opacity: 0;width: 100px;height: 100px}
}

.loading-screen>img{
    width: 100px;
    height: 100px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    animation-duration: 1s;
    animation-name: rotateanimation;
    animation-iteration-count: infinite;
}