.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #b8b8b8; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #3f3f3f; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #000000; 
}

.pointer{
  cursor: pointer;
}

html {
  scroll-behavior: smooth;
}

.social-link-block{
  position:fixed;
  display:block;
  width:100%;
  text-align:center;
  bottom:0;
  height:40px;
  padding-top: 20px;
  padding-bottom: 15px;
  /* padding-left: 25px; */
  z-index:4;
  background-color:#00000069;
}

.header-elements{
  display: flex;
  position: fixed;
  width: 100%;
  align-items: center;
  z-index: 10;

}

#countdownpage{
  background-color: #000000;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#countdown-block{
  position: relative;
  top: 50%;
  transform: translate(0,-50%);
  color: whitesmoke;  
  text-align: center;
  letter-spacing: 20px;
}

#countdown-block>div>h1{
  text-shadow: 1px 1px 10px #ffffffcc;
}
#countdown-block>img{
  width: 70px;
  filter: drop-shadow(1px 1px 8px white);
}

.header-elements::before{
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.header-elements-blend{
  mix-blend-mode: difference;
  filter: invert(0.1) grayscale(1) contrast(0.1);
}

.nabla9-text-bottle{
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 60px;
  z-index: 20;
}

@media only screen and (max-device-width: 500px){
  .social-link-block{
    padding-top: 15px;
    padding-left: 15px;
    padding-bottom: 5px;
    height:32px;
  }

#countdown-block>img{
  margin-bottom: 20px;
  margin-left: -5px;
}

#countdown-block h1{
  margin-block-start: 5px;
  margin-block-end: 5px;
}
#countdown-block{
  font-size: 12px;
  letter-spacing: 8px;
}

}