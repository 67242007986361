$backgroundcolor:rgb(139, 139, 139);

.main-bg-color{
    background-color: $backgroundcolor;
}

.news-body-block{    
    font-weight: 400;
    background-color: $backgroundcolor;    
    color: black;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    font-size: 6cqw;
    text-align: left;    
    max-height: 560px;
    overflow-y: auto;
    margin-bottom: 20px;
}

.news-block{
    width: 550px;
    margin-top: 100px;
    margin-bottom: 100px;
}
.modal-text-editor{
    display: flex;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    flex-wrap: wrap;
    max-width: 400px;
    display:inline-block;
}


@media only screen and (max-width: 700px) {
    .news-block {
        width: 80%;
        margin-top: 150px;
     }  
}

@media only screen and (max-device-width: 500px){
    .news-block {
        margin-top:40px;
    }
    .news-body-block{
        max-height: 100%;
    }
    .modal-text-editor{
        max-width: 90%;
    }
}

@media only screen and (max-device-height: 1000px){
    .news-body-block{
        max-height: 500px;
    }
}

@media only screen and (max-device-height: 800px){
    .news-body-block{
        max-height: 450px;
    }
}

@media only screen and (max-device-height: 700px){
    .news-body-block{
        max-height: 350px;
    }
}

.news-list{
    font-size: 4cqw;
    padding: 10px 10px 10px 10px;    
    border-top: 1px solid black;
    cursor: pointer;
}

.news-list:hover{
    color: rgb(233, 205, 247);
}

.news-list>div:first-child{
    font-weight: 650;
}

.news-body-content{
    font-size: 3.5cqw;
    padding-bottom: 10px;
}

.news-page-logo{
    width: 12%;
    position: relative;
    display: flex;
    left: 50%;
    transform: translate(-50%, 0);
    margin-top: 5%;
    margin-bottom: 10%;
}

