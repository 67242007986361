$lightgray:rgb(165, 165, 165);
$backgroundcolor:rgb(139, 139, 139);
$darkgray:rgb(82, 82, 82);
.gray-card{
    background-color: $backgroundcolor;
    width: 90%;
    max-width: 500px;
    min-height: 300px;
    margin-top: 100px;
    margin-bottom: 100px;
}

.gray-card #cart{
    position: relative;
    text-align: center;
    width: 90px;
    font-weight: 700;
    font-size: 20px;
    left: 50%;
    transform: translate(-50%,0);
    margin-top: -40px;
}

.gray-card #cart > img {
    width: 70px;
    padding-left: 10px;
}

.gray-card #cart > div {
    margin-top: -5px;
}

.gray-card #steps{
    text-align: center;
    margin-top: 20px;
}

.gray-card #steps > div{
    display: inline-block;
    width:30px;
    position: relative;
    font-size: 20px;
    font-weight: 500;
    overflow: visible;
}
.gray-card #steps > hr{
    display: inline-block;
    width:40px;
    margin-left:3px;
    margin-right:3px;
    background-color: white;
    height: 1px;
    border: 0;
}

.steps-text{
    position: absolute;
    font-size: 12px;
    left: 50%;
    transform: translate(-50%,0);
    width: 70px;
    margin-top: 3px;
    color:rgb(87, 87, 87)
}

.step-circle{
    color: white;
    background-color: rgb(207, 207, 207);
    border-radius: 50%;
    height: 30px;
    //padding-bottom: 2px;
}

.current-step{
    background-color: $darkgray;
}

.cart-progress-content{
    margin-top: 40px;
    padding-bottom: 40px;
    position: relative;
}

.cart-progress-content #choosetype{
    border: 1px solid black;
    width: 90%;
    position: relative;
    left: 50%;
    transform: translate(-50%,0);
    padding-top: 15px;
}

.cart-progress-content > #choosetype > #title {
    background-color: $backgroundcolor;
    position: absolute;
    font-size: 14px;
    top:-10px;
    width: 140px;
    text-align: center;
    left: 50%;
    transform: translate(-50%,0);
}

.radio-block{
    width: 100%;
    text-align: center;
    color: $darkgray;
}

.radio-block > div{
    display: inline-block;
    background-color: $lightgray;
    margin: 5px;
    padding: 3px;
    height: 25px;
    font-size: 16px;
    text-align: left;
    position: static;
    vertical-align: top;
}

.radio-block #takeatshow{
    width: 62%;
    padding-left: 2px;
    margin-left: 0px;
}

.radio-block #delivery{
    width: 29%;    
    padding-right: 2px;
    margin-right: 0px;
}

.radio-block > div > label{
    display: block;
    width: 100%;
    position: relative;
}

.radio-block > div > label > input {
    width: 16px;
    height: 16px;
    display: inline-block;
    vertical-align: top;
    position: absolute;
}

.radio-block > div > label #radio-title{
    display: block;
    position: absolute;
    width: 100%;
    text-align: center;
}
.radio-block > div > label > div > i {
    font-style: normal;
    color: $darkgray;
}

.goods-table #image-row > img{
    padding-top: 5px;
    width: 80%;
}

.goods-table #image-row{
    width: 25%;
}
.goods-table #name-row{
    width: 25%;
}
.goods-table #price-row{
    width: 25%;
}
.goods-table #count-row{
    width: 25%;
}

.goods-table{
    border: none;
    width: 90%;
    text-align: center;
    font-weight: 600;
}

.goods-table > tbody, .goods-table >tbody> tr, .goods-table >tbody>tr> td {
    border: none;
    border-bottom: 10px solid $backgroundcolor;
}

.goods-table > tbody > tr {
    background-color: $lightgray;
}

.cart-progress-content #goods{
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.goods-table #count-row > div{
    display: inline-block;
}

.goods-table #counter-element{
    color: white;
    background-color: rgb(82, 82, 82);
    border-radius: 50%;
    text-align: center;
    width: 14px;
    padding: 3px;
    cursor: pointer;    
    display: inline-block;    
    vertical-align: middle;
}

.goods-table #count-element{
    margin-right: 8px;
    margin-left: 8px;
}

.goods-table #price-row > div {
    display: inline-block;    
    vertical-align: middle;
}

.zeicomi-small-text{
    font-size: 10px;
    margin-top: 1px;
    margin-left: 3px;
}

.inline-inner-div > div{
    display: inline-block;    
    vertical-align: middle;
}

.total-price-block{
    border-top: 1px black solid;
    width:90%;
    text-align: right;
    position: relative;
    left: 50%;
    transform: translate(-50%,0);
    margin-top: 20px;
    padding-top: 5px;
    height: 40px;
}

.bold-st-number{
    font-size: 20px;
    font-weight: 600;
    color: black;
    margin-top: -3px;
}

.total-details-text{
    font-size: 12px;
    font-weight: 300;
    color: black;
    padding-right: 5px;
}


.cart-progress-content #buttons {
    width: 85%;
    display: flex;
    justify-content: space-between;
    position: relative;
    left: 50%;
    transform: translate(-50%,0);
}

.cart-progress-content #buttons > div {
    display: inline-block;
    width: 48%;
}

.cart-progress-content #waribiki{
    display: block;
    position: relative;
    width: 90%;
    left: 50%;
    transform: translate(-50%,0);    
    background-color: $lightgray;
    height: 35px;
    color: rgb(116, 0, 116);
}

.cart-progress-content #waribiki #waribiki-title{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    font-size: 12px;
}
.cart-progress-content #waribiki #waribiki-number{
    display: block;
    position: relative;
    width: 80%;
    text-align: right;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    font-size: 16px;
    font-weight: 600;
}

.cart-details-inputs{
    position: relative;
    width: 90%;
    left: 50%;
    transform: translate(-50%,0);
    border: 1px solid black;
    padding: 10px;
}

.cart-details-inputs > input,.cart-details-inputs >textarea, .cart-details-inputs >select {
    position: relative;
    left: 50%;
    transform: translate(-50%,0);
}

.cart-details-inputs > textarea{
    max-height: 30px;
}

.cart-details-inputs > label,.cart-details-inputs >input{
    display: block;
}

.cart-details-inputs > label{
    margin-top: 5px;
}

.cart-details-inputs #details-title{
    width: 100%;
    text-align: center;
}

.details-note{
    position: relative;
    width: 85%;
    left: 50%;
    transform: translate(-50%,0);
    border: 1px solid black;
    padding: 10px;
    padding-top: 15px;
    font-size: 12px;
    margin-top: 10px;
}

.details-note > #checkbox input{
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
}

.details-note > #checkbox{
    display: block;
    position: relative;
    text-align: center;
    padding-top: 5px;
}

.details-note > #checkbox > #chekboxtext {
    display: inline-block;
    vertical-align: top;
    margin-top: 3px;
}
.details-note > #checkbox > #chekboxtext > i {
    font-style: normal;
    font-size: 14px;
    font-weight: 600;
}

.details-note #header-title{
    display: block;
    position: absolute;
    top: -10px;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    background-color: $backgroundcolor;
    left: 50%;
    transform: translate(-50%, 0);
    width: 120px;
}

.overview-block{
    font-size: 16px;
    font-weight: 600;
}

.overview-block #overview-goods{
    border: 1px solid black;
    margin: 10px;
    padding: 0px;
}

.overview-block > #overview-goods > #title {
    padding-top: 10px;
}

.iverview-center-title {
    width: 100%;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
}

.overview-block > #overview-title {
    width: 100%;
    text-align: center;
    padding-bottom: 5px;
}

.overview-block #details {
    border: 1px solid black;
    margin: 10px;
    padding: 10px;
    font-size: 18px;
    font-weight: 400;
}

.overview-block > #details > .iverview-center-title{
    padding-bottom: 10px;
}

.overview-block > #details > label{
    display: block;
    width: 100%;
    text-align: center;
    font-size: 16px;
}

.overview-block > #details > #detail-value{
    padding-left: 20px;
    border-bottom: 1px solid black;
    margin-bottom: 10px;
}

.no-border #details{
    border: none;
    padding: 0px;
    margin: 0px;
    width: 90%;
    position: relative;
    left: 50%;
    transform: translate(-50%,0);
}
.no-border #overview-goods{
    border: none;
    width: 100%;
    margin: 0px;
    padding: 0px;
}

.overview-block > #details > #detail-title>label{
    padding-left: 10px;
    font-size: 15px;
}

.gray-card > #confirm-border{
    border: 1px solid black;
    margin: 10px;
    padding: 20px;
    padding-top: 0px;
}

.gray-card > #confirm-border > #header{
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 20px;
}

.gray-card > #confirm-border > .confirmation-text{
    font-size: 16px;
}

.gray-card>.go-back-buttons-text{
    font-size: 20px;
}

.gif-choose-type{
    width: 50px;
    position: absolute;
    z-index: 2;
    right: 120px;
    top:-45px;
    transform: rotate(-80deg);
}

@media only screen and (max-device-width: 500px){
    .radio-block > div #radio-title {
        font-size: 12px;
    }

    .gif-choose-type{
        width: 50px;
        position: absolute;
        z-index: 2;
        right: 60px;
        top:-45px;
        transform: rotate(-80deg);
    }
    
    .goods-table{
        font-size: 12px;
    }

    .gray-card{
        margin-top: 60px;
    }
    .radio-block > div > label #radio-title{
        margin-top: 2px;
    }
}